import React from "react";
import SkillProgress from "../../../common/SkillProgress";

function Categories({ designer }) {
  return (
    <div className="col-md-4 mt-4 mt-md-0 px-md-4">
      <h5 className="fw-semibold">Categories</h5>
      <div className="mx-auto mb-5">
        {designer?.designerCategory && designer.designerCategory.length > 0 ? (
          designer.designerCategory.map((category, index) => (
            <span
              className="me-2 badge mb-2 bg-main text-black p-1 px-3 rounded"
              key={index}
            >
              {category}
            </span>
          ))
        ) : (
          <p>No Categories added</p>
        )}
      </div>

      <h5 className="fw-semibold">Skills</h5>
      <div className="mb-5">
        {designer?.skills && designer.skills.length > 0 ? (
          designer.skills.map((skill, index) => (
            <div className="my-3" key={index}>
              <SkillProgress
                initialRating={skill.rating}
                skill={skill}
                skillsData={designer.skills}
              />
            </div>
          ))
        ) : (
          <p>No Skills added</p>
        )}
      </div>

      <h5 className="fw-semibold mt-5">Services</h5>
      <div className="mx-auto mb-5">
        {designer?.services && designer.services.length > 0 ? (
          designer.services.map((service, index) => (
            <span
              className="me-2 badge mb-2 bg-main text-black p-1 px-3 rounded"
              key={index}
            >
              {service}
            </span>
          ))
        ) : (
          <p>No Services added</p>
        )}
      </div>

      <div className="mx-auto mb-5">
        {(designer?.portfolioSocials?.website ||
          designer?.portfolioSocials?.facebook ||
          designer?.portfolioSocials?.instagram ||
          designer?.portfolioSocials?.twitter ||
          designer?.portfolioSocials?.pinterest) && (
          <div className="mx-auto mb-5">
            <h5 className="fw-semibold mt-5">Visit Me</h5>

            {designer?.portfolioSocials?.website && (
              <div className="my-1">
                <a
                  href={designer.portfolioSocials.website}
                  target="_blank"
                  rel="noreferrer"
                >
                  Website
                </a>
              </div>
            )}

            {designer?.portfolioSocials?.facebook && (
              <div className="my-1">
                <a
                  href={designer.portfolioSocials.facebook}
                  target="_blank"
                  rel="noreferrer"
                >
                  Facebook
                </a>
              </div>
            )}

            {designer?.portfolioSocials?.instagram && (
              <div className="my-1">
                <a
                  href={designer.portfolioSocials.instagram}
                  target="_blank"
                  rel="noreferrer"
                >
                  Instagram
                </a>
              </div>
            )}

            {designer?.portfolioSocials?.twitter && (
              <div className="my-1">
                <a
                  href={designer.portfolioSocials.twitter}
                  target="_blank"
                  rel="noreferrer"
                >
                  Twitter
                </a>
              </div>
            )}

            {designer?.portfolioSocials?.pinterest && (
              <div className="my-1">
                <a
                  href={designer.portfolioSocials.pinterest}
                  target="_blank"
                  rel="noreferrer"
                >
                  Pinterest
                </a>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default Categories;
