import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { userLogout } from "../../../redux/actions/userActions";
import { designerLogout } from "../../../redux/actions/designerActions";
import { adminLogout } from "../../../redux/actions/adminActions";

function Navbar() {
  const dispatch = useDispatch();

  const [isScrolled, setIsScrolled] = useState(false);

  const { token: userToken } = useSelector((state) => state.userData);
  const { token: designerToken } = useSelector((state) => state.designerData);
  const { token: adminToken } = useSelector((state) => state.adminData);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleLogout = () => {
    window.location.reload();

    dispatch(userLogout());
    dispatch(designerLogout());
    dispatch(adminLogout());
  };

  return (
    <nav
      className={`navbar navbar-expand-lg fixed-top ${
        isScrolled ? "bg-white" : "bg-main"
      }`}
    >
      <div className="container">
        <Link className="navbar-brand" to="/">
          <img src="/assets/images/logo.png" width={"40px"} alt="logo" />
        </Link>

        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <Link
                className="nav-link nav-color"
                aria-current="page"
                to="/aboutus"
              >
                About Us
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link nav-color"
                aria-current="page"
                to="/designers"
              >
                Search Designers
              </Link>
            </li>
            {userToken || designerToken ? (
              <li>
                <Link
                  className="nav-link nav-color"
                  to={`/${
                    userToken ? "user" : designerToken ? "designer" : ""
                  }/dashboard`}
                >
                  Dashboard
                </Link>
              </li>
            ) : null}

            <li className="nav-item">
              {!(userToken || designerToken || adminToken) && (
                <Link className="nav-link nav-color" to={"/register"}>
                  Create a Portfolio
                </Link>
              )}
            </li>
            <li className="nav-item">
              {userToken || designerToken || adminToken ? (
                <button
                  type="button"
                  className="text-start btn rounded-0 w-100 nav-link nav-color"
                  onClick={handleLogout}
                >
                  Logout
                </button>
              ) : (
                <Link className="nav-link nav-color" to="/login">
                  Login
                </Link>
              )}
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
