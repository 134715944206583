import React, { useEffect, useState } from "react";
import { RiAddCircleLine, RiEditLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
  deletePortfolio,
  getPortfoliosByDesigner,
  updatePortfolio,
} from "../../api/Api";
import { useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import DeletePortfolioDialog from "./DeletePortfolioDialog";

const showModal = (modalId) => {
  const modal = new window.bootstrap.Modal(document.getElementById(modalId));
  modal.show();
};

function DesignerManagePortfolios() {
  const { token: designerToken } = useSelector((state) => state.designerData);
  const [portfolios, setPortfolios] = useState([]);
  const [filteredPortfolios, setFilteredPortfolios] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");

  useEffect(() => {
    const fetchPortfolios = async () => {
      setLoading(true);
      const result = await getPortfoliosByDesigner(designerToken);

      if (result.status === "success") {
        setPortfolios(result.data);
        setFilteredPortfolios(result.data);
      }
      setLoading(false);
    };

    fetchPortfolios();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const result = portfolios.filter((portfolio) =>
      portfolio.title.toLowerCase().includes(search.toLowerCase())
    );
    setFilteredPortfolios(result);
  }, [search, portfolios]);

  const handleUpdateStatus = async (portfolio) => {
    const result = await updatePortfolio(
      {
        ...portfolio,
        isActive: !portfolio.isActive,
        portfolioId: portfolio._id,
      },
      designerToken
    );

    if (result.status === "success") {
      setPortfolios((oldPortfolios) =>
        oldPortfolios.map((item) =>
          item._id === portfolio._id
            ? { ...item, isActive: !item.isActive }
            : item
        )
      );
      setFilteredPortfolios((oldPortfolios) =>
        oldPortfolios.map((item) =>
          item._id === portfolio._id
            ? { ...item, isActive: !item.isActive }
            : item
        )
      );
      toast.success("Portfolio Status Updated Successfully");
    } else {
      toast.error(result.message);
    }
  };

  const handlePortfolioDelete = async (id) => {
    const result = await deletePortfolio(id, designerToken);

    if (result.status === "success") {
      setPortfolios(portfolios.filter((portfolio) => portfolio._id !== id));
      setFilteredPortfolios(
        filteredPortfolios.filter((portfolio) => portfolio._id !== id)
      );
      toast.success("Portfolio Deleted Successfully");
    } else {
      toast.error(result.message);
    }
  };

  const columns = [
    {
      name: "No",
      selector: (row, index) => index + 1,
      sortable: true,
      width: "70px",
      left: true,
    },
    {
      name: "Title",
      selector: (row) => row.title,
      sortable: true,
      wrap: true,
      left: true,
      width: "30%", // Adjust this value based on your needs
    },
    {
      name: "Views",
      selector: (row) => row.views,
      sortable: true,
      left: true,
      width: "100px",
    },
    {
      name: "Status",
      cell: (row) => (
        <span
          className={`badge ${
            row.isActive ? "bg-success-alt" : "bg-danger-alt"
          } text-white`}
        >
          {row.isActive ? "Active" : "Private"}
        </span>
      ),
      sortable: true,
      left: true,
      width: "120px",
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="d-flex gap-1">
          <Link
            to={`/designer/portfolio/edit/${row._id}`}
            style={{ fontSize: 12.25 }}
            className="d-flex align-items-center gap-1 btn btn-sm btn-primary"
          >
            <RiEditLine />
            Edit
          </Link>
          <button
            style={{ fontSize: 12.25 }}
            className="d-flex align-items-center gap-1 btn btn-sm btn-warning"
            onClick={() => handleUpdateStatus(row)}
          >
            {row.isActive ? "Private" : "Public"}
          </button>
          <button
            style={{ fontSize: 12.25 }}
            onClick={() => showModal(`delete-portfolio-dialog-${row._id}`)}
            className="d-flex align-items-center gap-1 btn btn-sm btn-danger"
          >
            Delete
          </button>
          <DeletePortfolioDialog
            id={row._id}
            handlePortfolioDelete={handlePortfolioDelete}
          />
        </div>
      ),
      minWidth: "300px",
      right: true,
    },
  ];

  return (
    <section className="dashboard-section designer-manage-portfolios">
      <div
        className="d-flex gap-2 flex-wrap justify-content-between align-items-center"
        style={{ padding: "19px 24px", marginBottom: 5 }}
      >
        <h4
          className="text-nowrap"
          style={{ fontWeight: 700, fontSize: 15, color: "#495047" }}
        >
          MANAGE PORTFOLIO
        </h4>
        <ol className="breadcrumb m-0 flex-nowrap text-nowrap">
          <li className="fs-14 breadcrumb-item">
            <Link to="/designer/dashboard">Dashboard</Link>
          </li>
          <li className="fs-14 breadcrumb-item active">Manage Portfolio</li>
        </ol>
      </div>
      <div className="section-spacing">
        <div className="card manage-portfolios-card">
          <div className="card-header d-flex flex-wrap gap-2 align-items-center justify-content-between">
            <h5
              className="card-title mb-0 flex-grow-1"
              style={{ minWidth: "fit-content" }}
            >
              Your Portfolio
            </h5>
            <Link
              to="/designer/portfolio/add"
              style={{ fontSize: 12.25 }}
              className="d-flex align-items-center gap-1 btn btn-sm btn-primary"
            >
              <RiAddCircleLine size={24} />{" "}
              <span className="text-nowrap">Add New Project</span>
            </Link>
          </div>
          <div className="card-body fs-14">
            <div className="d-flex justify-content-end">
              <div className="mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search by Title"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  style={{ maxWidth: "300px", height: "28px" }}
                />
              </div>
            </div>
            <DataTable
              columns={columns}
              data={filteredPortfolios}
              progressPending={loading}
              style={{ width: "100%" }}
              pagination
              highlightOnHover
              striped
              responsive
              persistTableHead
              noDataComponent="No Projects Found."
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default DesignerManagePortfolios;
