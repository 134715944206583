import React from "react";

function Breadcrumb() {
  return (
    <div className="text-center mt-5">
      <div className="display-5 mt-3">Privacy Policy</div>
    </div>
  );
}

export default Breadcrumb;
