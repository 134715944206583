import React from "react";
import { Link } from "react-router-dom";

function FindDream() {
  return (
    <div className="bg-main">
      <div className="container">
        <div className=" py-5 text-center">
          <div className="row">
            <div className="col-lg-8 mx-auto ">
              <div className="text-center">
                <h1 className="finddream-text-font mb-4">
                  Searching for Your Next Rock Star Designer?
                </h1>
                <p className="finddream-paragraph mb-4">
                  Join top fashion brands in discovering elite talent with I AM
                  A DESIGNER ! <br /> Explore our curated collection of
                  trendsetting designers and find the perfect creative visionary
                  to elevate your next project.
                </p>
                <Link
                  to={"/designers"}
                  className="rounded-5 p-3 px-5 btn bg-black fw-bold text-white mb-4"
                >
                  Get started now
                </Link>
                <div className="finddream-underline">
                  {" "}
                  Are you a designer?{" "}
                  <span>
                    {" "}
                    <Link to={"/register"}>
                      <u>Showcase Your Portfolio</u>
                    </Link>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FindDream;
