import React from "react";
import Navbar from "../components/common/landing/Navbar";
import Footer from "../components/common/landing/Footer";
import DesignerProfile from "../components/LandingPage/home/DesignerProfile/DesignerProfile";
function DesignerProfiles() {
  return (
    <div>
      <Navbar />
      <DesignerProfile />
      <Footer />
    </div>
  );
}

export default DesignerProfiles;
