import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import {
  FaFacebook,
  FaInstagram,
  FaPinterest,
  FaTwitter,
} from "react-icons/fa";

function Footer() {
  useEffect(() => {
    window.scrollTo(-1, -1);
  }, []);
  return (
    <div className="container mt-5">
      <div className="d-flex flex-column flex-md-row justify-content-between align-items-center border-bottom pt-5">
        <div className="mb-3 mb-md-0">
          <img src="/assets/images/footerlogo.png" width={"170px"} alt="" />
        </div>
        <div className="d-flex  gap-3  gap-md-4 fw-bold mb-5 mb-md-0">
          <Link to="/register">
            <small>For Designers</small>
          </Link>
          <Link to="/designers">
            <small>Hire Talent</small>
          </Link>
          <Link to="/aboutus">
            <small>About Us</small>
          </Link>
          <Link to="/contactus">
            <small>Contact Us</small>
          </Link>
        </div>
        <div className="d-flex  gap-3 gap-md-4 fs-5">
          <Link to="#">
            <small>
              <FaTwitter />
            </small>
          </Link>
          <Link to="#">
            <small>
              <FaFacebook />
            </small>
          </Link>
          <Link to="#">
            <small>
              <FaInstagram />
            </small>
          </Link>
          <Link to="#">
            <small>
              <FaPinterest />
            </small>
          </Link>
        </div>
      </div>
      <div className="d-flex flex-column flex-md-row justify-content-md-between py-4">
        <div className=" text-center text-md-start">
          <small className="copyright-text">© 2024 I AM A DESIGNER</small>
        </div>
        <div className="d-flex justify-content-center justify-content-md-start">
          <Link to="/privacy-policy" className="copyright-text me-md-3">
            <small>Privacy & Policy</small>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Footer;
