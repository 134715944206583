import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { RiAddFill, RiCloseFill, RiPlayFill } from "react-icons/ri";
import {
  singleImageUpload,
  updateDesignerData,
  updatePortfolio,
} from "../../api/Api";
import Loader from "./Loader";
import { setDesigner } from "../../redux/actions/designerActions";
import { useParams } from "react-router-dom";

function VideoGallery({ clientData, setClientData, token, mode, ...props }) {
  const dispatch = useDispatch();

  const inputRef = useRef();
  const [videoGallery, setVideoGallery] = useState([]);
  const [loading, setLoading] = useState();
  const [deleteLoading, setDeleteLoading] = useState("");

  const { id } = useParams();

  useEffect(() => {
    setVideoGallery(clientData.videos || []);
  }, [clientData]);

  const handleFileChange = async (e) => {
    const file = e.target.files[0];

    if (videoGallery.length >= 5) {
      toast.error(`You can upload max ${5} Videos`);
      return;
    }

    if (file) {
      setLoading(true);
      const formdata = new FormData();
      formdata.append("image", file);

      const result = await singleImageUpload(formdata);
      if (result.status === "success") {
        const videoUrl = result.data;

        if (mode === "portfolio-add") {
          setVideoGallery([...videoGallery, videoUrl]);
          setClientData((oldClientData) => ({
            ...oldClientData,
            videos: [...videoGallery, videoUrl],
          }));
        } else {
          updateClientProfile([...videoGallery, videoUrl]);
        }
      } else {
        toast.error("Fail To Upload Video");
      }
    }
    setLoading(false);
    e.target.value = "";
  };

  const updateClientProfile = async (updatedVideoGallery) => {
    switch (mode) {
      // DESIGNER
      case "designer":
        {
          const result = await updateDesignerData(
            { videos: updatedVideoGallery },
            token
          );

          if (result.status === "success") {
            toast.success("Media Gallery Updated Successfully");
            dispatch(setDesigner(result.data));
            setVideoGallery(updatedVideoGallery);
            setClientData((oldClientData) => ({
              ...oldClientData,
              videos: updatedVideoGallery,
            }));
          } else {
            toast.error(result.message);
          }
        }
        break;

      // PORTFOLIO
      case "portfolio-edit":
        {
          const result = await updatePortfolio(
            {
              ...clientData,
              videos: updatedVideoGallery,
              portfolioId: id,
            },
            token
          );

          if (result.status === "success") {
            toast.success("Media Gallery Updated Successfully");
            setVideoGallery(updatedVideoGallery);
            setClientData((oldClientData) => ({
              ...oldClientData,
              videos: updatedVideoGallery,
            }));
          } else {
            toast.error(result.message);
          }
        }
        break;

      // DEFAULT
      default:
        return null;
    }

    setDeleteLoading("");
  };

  const handleDeleteVideo = async (index) => {
    const updatedVideoGallery = videoGallery.filter((_, i) => i !== index);
    setDeleteLoading(videoGallery[index]);

    if (mode === "portfolio-add") {
      setVideoGallery(updatedVideoGallery);
      setClientData((oldClientData) => ({
        ...oldClientData,
        videos: updatedVideoGallery,
      }));
    } else {
      updateClientProfile(updatedVideoGallery);
    }
  };

  return (
    <div className={`flex-grow-1 mb-3 ${props.className}`}>
      <div className="mb-3">
        <label htmlFor="videos" className="form-label">
          Upload Multiple Videos <small>(mp4, mkv, Less than 10Mb)</small>
        </label>
        <input
          type="file"
          id="videos"
          className="form-control fs-14"
          name="videos"
          accept="video/*"
          onChange={handleFileChange}
          disabled={loading}
          ref={inputRef}
        />
      </div>
      <div className="d-flex gap-3 flex-wrap">
        {videoGallery.map((video, index) => (
          <div
            key={index}
            className="mt-3 p-2 rounded-3"
            style={{ backgroundColor: "#fbf7f4" }}
          >
            <div className="position-relative flex-grow-1 avatar-xl">
              <div className="overflow-hidden rounded-3 h-100">
                <a href={video} target="_blank" rel="noreferrer noopener">
                  <img
                    src={video?.replace(".mp4", ".jpg")}
                    alt={`Gallery ${index + 1}`}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                </a>
                <button
                  type="button"
                  className="btn btn-light d-flex align-items-center p-0 position-absolute"
                  onClick={() => handleDeleteVideo(index)}
                  style={{
                    aspectRatio: "1 / 1",
                    top: 5,
                    right: 5,
                  }}
                  disabled={deleteLoading === video}
                >
                  {deleteLoading === video ? (
                    <Loader size={12} color="black" />
                  ) : (
                    <RiCloseFill size={14} />
                  )}
                </button>
              </div>
              <div
                className="position-absolute"
                style={{
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  pointerEvents: "none",
                }}
              >
                <RiPlayFill size={32} color="white" />
              </div>
            </div>
          </div>
        ))}
        <div
          className="mt-3 p-2 rounded-3"
          style={{ backgroundColor: "#fbf7f4" }}
        >
          <div className="avatar-xl d-flex justify-content-center align-items-center">
            <button
              type="button"
              className="btn btn-light d-flex align-items-center p-0 rounded-pill"
              onClick={() => {
                inputRef.current.click();
              }}
              style={{
                background: "none",
                aspectRatio: "1 / 1",
              }}
              disabled={loading}
            >
              <RiAddFill size={32} color="#826f2b" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VideoGallery;
