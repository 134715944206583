import React from "react";
import Breadcrumb from "./Breadcrumb";
import { Link } from "react-router-dom";

function Main() {
  return (
    <div className="container">
      <div className="mt-5 pt-3">
        <Breadcrumb />
      </div>
      <div className="d-flex justify-content-center my-4">
        <span className="w-75">
          Welcome to{" "}
          <Link to={"/"} className="fw-semibold">
            I AM A DESIGNER
          </Link>{" "}
          ! Your privacy and the security of your information are incredibly
          important to us. This Privacy Policy outlines how we collect, use,
          protect, and share your information when you interact with our website
          and services. By using{" "}
          <Link to={"/"} className="fw-semibold">
            I AM A DESIGNER
          </Link>{" "}
          , you agree to the terms of this policy.
        </span>
      </div>
      <div className="d-flex justify-content-center mt-2">
        <span className="w-75 mb-2">
          <h5 className="fw-semibold mb-0">Information We Collect</h5>
          We gather various types of information to provide and improve our
          services:
          <ul className="mt-2">
            {" "}
            <li className="fw-semibold">Personal Information</li>
            <span>
              {" "}
              Details such as your name, email address, and phone number that
              you provide when creating an account, updating your profile, or
              contacting us.
            </span>
          </ul>
          <ul className="mt-2">
            {" "}
            <li className="fw-semibold">Design Portfolios and Profiles</li>
            <span>
              {" "}
              Content you upload, including images, descriptions, and project
              details that showcase your work.
            </span>
          </ul>
          <ul className="mt-2">
            {" "}
            <li className="fw-semibold">Usage Data</li>
            <span>
              {" "}
              Information about your interactions with our site, such as IP
              addresses, browser types, and pages visited, to help us enhance
              your experience.
            </span>
          </ul>
        </span>
      </div>
      <div className="d-flex justify-content-center mt-2">
        <span className="w-75 mb-2">
          <h5 className="fw-semibold mb-0">How We Use Your Information</h5>
          We use your information in the following ways:
          <ul className="mt-2">
            {" "}
            <li className="fw-semibold">To Provide and Enhance Our Services</li>
            <span>
              {" "}
              To manage your account, showcase your portfolio, and deliver a
              personalized experience
            </span>
          </ul>
          <ul className="mt-2">
            {" "}
            <li className="fw-semibold">To Communicate with You</li>
            <span>
              {" "}
              To send updates, respond to your queries, and provide customer
              support.
            </span>
          </ul>
          <ul className="mt-2">
            {" "}
            <li className="fw-semibold">To Analyze and Improve Our Site</li>
            <span>
              {" "}
              To understand user behavior and make improvements to our platform.
            </span>
          </ul>
        </span>
      </div>
      <div className="d-flex justify-content-center mt-2">
        <span className="w-75 mb-2">
          <h5 className="fw-semibold mb-0">How We Share Your Information</h5>
          We may share your information with:
          <ul className="mt-2">
            {" "}
            <li className="fw-semibold">Service Providers</li>
            <span>
              {" "}
              Trusted third-party vendors who assist us in operating our site
              and providing support.
            </span>
          </ul>
          <ul className="mt-2">
            {" "}
            <li className="fw-semibold">Legal Authorities</li>
            <span>
              {" "}
              When required by law, to comply with legal obligations, protect
              our rights, or respond to government requests.
            </span>
          </ul>
          <ul className="mt-2">
            {" "}
            <li className="fw-semibold">Business Transfers</li>
            <span>
              {" "}
              In the event of a merger, acquisition, or other business
              transactions, your information may be transferred as part of the
              deal.
            </span>
          </ul>
        </span>
      </div>
      <div className="d-flex justify-content-center mt-2">
        <span className="w-75 mb-2">
          <h5 className="fw-semibold mb-0">
            Cookies and Tracking Technologies
          </h5>
          We use cookies and similar technologies to enhance your browsing
          experience. Cookies are small data files stored on your device that
          help us remember your preferences and track site usage. You can manage
          your cookie preferences through your browser settings.
        </span>
      </div>
      <div className="d-flex justify-content-center mt-2">
        <span className="w-75 mb-2">
          <h5 className="fw-semibold mb-0">Data Security </h5>
          We take your privacy seriously and implement robust measures to
          protect your data from unauthorized access, misuse, or loss. Despite
          our best efforts, no online system can be completely secure. We
          encourage you to use strong passwords and safeguard your account
          information. If you suspect any unauthorized access to your account,
          please contact us immediately.
        </span>
      </div>
      <div className="d-flex justify-content-center mt-2">
        <span className="w-75 mb-2">
          <h5 className="fw-semibold mb-0">
            Designers' Responsibility for Portfolio Security
          </h5>
          As a designer, you play a crucial role in keeping your portfolio and
          personal information secure. Please ensure you use strong, unique
          passwords and keep your account details confidential. Regularly review
          your portfolio for any unauthorized changes and notify us immediately
          if you notice anything suspicious. While we implement strong security
          measures, your proactive steps help us maintain a secure environment
          for everyone
        </span>
      </div>
      <div className="d-flex justify-content-center mt-2">
        <span className="w-75 mb-2">
          <h5 className="fw-semibold mb-0">Your Rights and Choices</h5>
          You have the following rights regarding your information:
          <ul className="mt-2">
            {" "}
            <li className="fw-semibold">Access and Update</li>
            <span> Review and modify your account information as needed.</span>
          </ul>
          <ul className="mt-2">
            {" "}
            <li className="fw-semibold">Request Deletion</li>
            <span>
              {" "}
              Ask us to delete your account and associated data, subject to any
              legal or service-related requirements.
            </span>
          </ul>
          <ul className="mt-2">
            {" "}
            <li className="fw-semibold">Opt-Out of Marketing</li>
            <span>
              {" "}
              Unsubscribe from our marketing communications by following the
              instructions provided in those emails.
            </span>
          </ul>
        </span>
      </div>
      <div className="d-flex justify-content-center mt-2">
        <span className="w-75 mb-2">
          <h5 className="fw-semibold mb-0">Changes to This Privacy Policy</h5>
          We may update this Privacy Policy from time to time. Any changes will
          be reflected on this page with an updated effective date. We encourage
          you to review this policy periodically to stay informed about how we
          protect your information.
        </span>
      </div>
      <div className="d-flex justify-content-center mt-2">
        <span className="w-75 mb-2">
          <h5 className="fw-semibold mb-0">Contact Us</h5>
          If you have any questions or concerns about this Privacy Policy or our
          data practices, please reach out to us:
          <div className="mt-2">
            <div className="d-flex align-items-center gap-2">
              <h6 className="fw-bold">Email:</h6>
              <h6>Hello@iamadesigner.com</h6>
            </div>
            <div className="d-flex align-items-center gap-2">
              <h6 className="fw-bold">Address:</h6>
              <h6>5 W. 37th St. New York, NY 10018</h6>
            </div>
          </div>
        </span>
      </div>
    </div>
  );
}

export default Main;
