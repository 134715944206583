import React from "react";
import { Link } from "react-router-dom";

function Hero() {
  return (
    <>
      <div className="img-hero-container">
        <img
          src="/assets/images/heroimg.jpeg"
          alt="Hero"
          className="img-hero"
        />
        <div className="logo-container d-none d-md-block">
          <div className="text-center">
            <img
              src="/assets/images/footerlogo.png"
              width={"300"}
              alt="Logo"
              className="logo-img"
            />
          </div>
          <div className="d-flex justify-content-center">
            <div className="text-center text-white">
              <Link
                to={"/designers"}
                className="btn rounded-3 px-5 py-2 custom-btn text-white my-2"
              >
                Find a Designer
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="d-md-none d-block">
        <div className="text-center">
          <img
            src="/assets/images/footerlogo.png"
            width={"250"}
            alt="Logo"
            className="logo-img"
          />
        </div>
        <div className="d-flex justify-content-center">
          <div className="text-center text-black">
            <h6>Showcasing Designers. Connecting Opportunities.</h6>
            <Link
              to={"/designers"}
              className="btn rounded-3 px-5 py-2 custom-btn text-white my-3"
            >
              Find Dream Designers
            </Link>
          </div>
        </div>
      </div>
      <div
        className="pt-5 pb-2 text-center prose mx-auto"
        style={{maxWidth: "50%"}}
      >
        <h1 className="my-3">Showcasing Designers. Connecting Opportunities</h1>
        <p className="finddream-paragraph">
          {" "}
          We know that design is about more than just creating something
          beautiful - it's about putting your heart and soul into what you do.
          That's why we've built a space where you can easily create your
          profile, share your unique journey, and get discovered by brands eager
          to work with designers like you.{" "}
        </p>
        <p className="finddream-paragraph">Welcome to I AM A DESIGNER , where creativity meets opportunity.</p>
      </div>
    </>
  );
}

export default Hero;
